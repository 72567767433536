const validate = require('validate.js');

if (document.querySelectorAll('[data-component="validate"]').length > 0) {
    
    document.addEventListener('DOMContentLoaded', function () {
        const form = document.querySelector('[data-component="validate"]');

        // the constraints used to validate the form
        const constraints = {
            email: {
                presence: true,
                email: true
            }
        }

        // Shows the errors for a specific input
        function showErrorForInput(input, err) {
            let formGroup = closestParent(input.parentNode, 'form-group');
            let messages = formGroup.querySelector('.form-error');
        }

        // show error
        function showError(form, err) {
            _.each(form.querySelectorAll('"input[name], select[name]"'), function (input) {

            })
        }

        function handleSubmit(form, input) {
            let errors = validate(form, constraints);

            showError(form, errors || {});

            if (!errors) {
                return true
            }
        }

        form.addEventListener("submit", function (e) {
            e.preventDefault()
            handleSubmit(form)
        })
    })
}