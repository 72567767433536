if (document.querySelectorAll('[data-component="marquee"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
        
        function Marquee(selector, speed) {
            const parentSelector = document.querySelector(selector);
            const clone = parentSelector.innerHTML;
            const firstElement = parentSelector.children[0];
            let i = 0;
            
            parentSelector.insertAdjacentHTML('beforeend', clone);
            parentSelector.insertAdjacentHTML('beforeend', clone);
          
            setInterval(function () {
                firstElement.style.marginLeft = `-${i}px`;
                if (i > firstElement.clientWidth) {
                    i = 0;
                }

                i = i + speed;
            }, 0);
        }

        window.addEventListener('load', Marquee('.notice', .5))
    })
}