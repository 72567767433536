if (document.querySelectorAll('[data-component="hamburger"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelector('[data-toggle="hamburger"]');
        const dismissToggle = document.querySelector('[data-dismiss="hamburger"]');
        const target = document.querySelector('.hamburger');
        
        toggle.addEventListener('click', function () {
            this.classList.add('show');
            target.classList.add('show');
        });

        dismissToggle.addEventListener('click', function () {
            toggle.classList.remove('show');
            target.classList.remove('show');
        })
    })
}